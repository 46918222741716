<template>
  <div class="othercase">
    <div class="CaseContent">
      <div class="title">
        <div>
          <i class="iconfont icon-back" @click="goback"></i>
          <span>{{ this.$route.query.name }}</span>
        </div>
      </div>
      <div class="com" ref="comon">
        <ul class="company" v-for="(item, index) in mainarr" :key="index">
          <li @click="decade(item.doc_id)">{{ item.title }}</li>
          <li>
            <p>{{ item.court.trim() }}</p>
            <p>/{{ item.case_id.trim() }}</p>
            <p>/{{ item.pub_date.trim() }}</p>
          </li>
        </ul>
        <loading :showflag="display"></loading>
      </div>
    </div>
    <div v-if="encounter" class="enclose">
      <precedent :id="scan" @changeflag="scandal"></precedent>
    </div>
  </div>
</template>
<script>
import { pydataBase } from '~api-config'
import { tools } from '@/pages/Search/compoments/tool'
import loading from '@/pages/Search/compoments/loading'
import precedent from '@/pages/precedentDetail'
export default {
  components: { loading, precedent },
  data() {
    return {
      mainarr: [],
      display: 1,
      scan: null,
      encounter: false
    }
  },
  methods: {
    decade(id) {
      this.scan = id
      this.encounter = true
    },
    scandal() {
      this.encounter = false
    },
    goback() {
      this.$router.go(-1)
    }
  },
  mounted() {
    let flag = true
    let scroll = null
    scroll = tools.scroll(this.$refs.comon, num => {
      console.log(num)
      this.display = 1
      if (!flag) {
        return
      }
      flag = false
      /* ?name=${this.$route.query.name}&slug=1&offset=${num}&show=1 */
      this.$axios
        .post(`${pydataBase}/api/party/card`, {
          name: this.$route.query.name,
          slug: 1,
          offset: num,
          show: 1
        })
        .then(res => {
          console.log(res.data.result)
          if (res.data.result.length === 0) {
            this.display = 0
            scroll()
            return
          }
          flag = true
          this.display = 2
          this.mainarr = this.mainarr.concat(res.data.result)
          console.log(this.mainarr)
          this.mainarr.forEach(item => {})
          /* ?name=${this.$route.query.name}&slug=1&offset=1&show=1 */
          this.$axios
            .post(`${pydataBase}/api/party/card`, {
              name: this.$route.query.name,
              slug: 1,
              offset: 1,
              show: 1
            })
            .then(res => {
              this.mainarr.forEach(item => {})
              this.display = 2
            })
        })
    })
  }
}
</script>

<style lang="stylus">
.othercase
  width 100%
  height 100%
  .enclose
    width 100%
    height 100%
    position fixed
    z-index 9999
    background #ffffff
    top 0px
    left 0px
  .CaseContent
    width 100%
    padding-bottom 10px
    margin 5px auto 0px
    overflow hidden
    height 100%
    .com
      width 100%
      height calc(100% - 45px)
      overflow auto
      padding 0px 15px
      box-sizing border-box
    .company
      width 100%
      border-bottom 1px solid #F3F4F7
      overflow hidden
      margin-top 10px
    li:nth-child(1)
      font-size 15px !important
      color #000000
    li:nth-child(2)
      color #999999
      font-size 12px
      margin-bottom 7px
      p:nth-child(1)
        max-width 27%
        height 20px
        white-space nowrap
        text-overflow ellipsis
        overflow hidden
        float left
      p:nth-child(2)
        max-width 50%
        height 20px
        white-space nowrap
        text-overflow ellipsis
        overflow hidden
        float left
      p:nth-child(3)
        max-width 22%
        height 20px
        white-space nowrap
        text-overflow ellipsis
        overflow hidden
        float left
    .title
      width 100%
      display flex
      height 45px
      line-height 45px
      justify-content space-between
      font-size 15px
      color #000000
      border-bottom 1px solid #F3F4F7
      position fixed
      background #ffffff
      z-index 999
      top 0px
      div
        span
          margin-left 10px
</style>
